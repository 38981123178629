import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import {SxProps, Theme} from '@mui/material';
import {Link, useLocation} from 'react-router-dom';
import ReactGA from 'react-ga4';
import GoogleAd from "../add/GoogleAd";

export const pages = [
    {name: 'Kalkulaator', path: '/'},
    {name: 'Info', path: '/info'},
    {name: 'Uudised', path: '/uudised'},
];

const style: SxProps<Theme> = {
    background: '#F0F0F0', // Light gray background
    color: '#333', // Dark gray text
    paddingY: '20px',
    width: '100%', // Set the width to 100%
};

const ResponsiveAppBar: React.FC = () => {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const location = useLocation(); // This hook gives you the current location

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handlePageClick = (index: number) => {
        ReactGA.event({
            category: 'User',
            action: 'Changed tab',
            label: pages[index].name,
        });
    };

    return (
        <>
            <AppBar position="static" sx={style}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon/>
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: {xs: 'block', md: 'none'},
                                }}
                            >
                                {pages.map((page, index) => (
                                    <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                                        <Typography textAlign="center">
                                            <Link
                                                to={page.path}
                                                style={{textDecoration: 'none', color: '#333'}}
                                                onClick={() => handlePageClick(index)}
                                            >
                                                {page.name}
                                            </Link>
                                        </Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: {xs: 'none', md: 'flex'},
                                justifyContent: 'center',
                                textAlign: 'center',
                            }}
                        >
                            {pages.map((page, index) => (
                                <Button
                                    key={page.name}
                                    sx={{
                                        my: 2,
                                        mx: 3,
                                        color: '#333',
                                        border: 'none',
                                        display: 'block',
                                        fontSize: '1.5rem',
                                        textDecoration: location.pathname === page.path ? 'underline' : 'none',
                                    }}
                                >
                                    <Link
                                        to={page.path}
                                        style={{textDecoration: 'none', color: '#333'}}
                                        onClick={() => handlePageClick(index)}
                                    >
                                        {page.name}
                                    </Link>
                                </Button>
                            ))}
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <Box sx={{textAlign: 'center', marginTop: '20px'}}>
                <GoogleAd
                    client="ca-pub-7424330364505566" // Replace with your AdSense publisher ID
                    slot="6239650534" // Replace with your AdSense ad slot ID
                    style={{display: 'block', margin: '0 auto'}}
                    adFormat="horizontal"
                    responsive="true"
                />
            </Box>
        </>
    );
};

export default ResponsiveAppBar;