import AdapterDateFns from '@date-io/date-fns';
import {
    Box,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
    useMediaQuery,
} from '@mui/material';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {DatePicker} from '@mui/x-date-pickers/DatePicker/DatePicker';
import {et} from 'date-fns/locale';
import {useEffect, useState} from 'react';
import {arvutaAastamaks, arvutaRegistreerimismaks, AutoTüüp, CO2Tüüp, MootoriTüüp,} from './CalculateTaxes';
import TotalSection from './TotalSection';
import './styles.css';
import GoogleAd from "../add/GoogleAd";

export interface ContentProps {
    isMobile: boolean;
}

const Content = (props: ContentProps) => {
    const [auto, setAuto] = useState<AutoTüüp>(AutoTüüp.SÕIDUAUTO);
    const [vanus, setVanus] = useState<number>(0);
    const [mootor, setMootor] = useState<MootoriTüüp>(
        MootoriTüüp.SISEPÕLEMISMOOTORIGA
    );
    const [co2Tüüp, setCo2Tüüp] = useState<CO2Tüüp>(CO2Tüüp.WLTP);
    const [co2, setCo2] = useState<number>(0);
    const [mass, setMass] = useState<number>(0);
    const [maht, setMaht] = useState<number>(0);
    const [võimsus, setVõimsus] = useState<number>(0);
    const [aastamaks, setAastamaks] = useState<number>(0);
    const [registreerimismaks, setRegistreerimismaks] = useState<number>(0);
    const [selectedDate, setSelectedDate] = useState(null as Date | null);

    const isMobile = useMediaQuery('(max-width: 1080px)');

    useEffect(() => {
        setAastamaks(
            arvutaAastamaks({
                vanus,
                auto,
                mootor,
                co2Tüüp,
                co2,
                mass,
                maht,
                võimsus,
            })
        );
        setRegistreerimismaks(
            arvutaRegistreerimismaks({
                vanus,
                auto,
                mootor,
                co2Tüüp,
                co2,
                mass,
                maht,
                võimsus,
            })
        );
    }, [auto, vanus, mootor, co2Tüüp, co2, mass, maht, võimsus]);

    const calculateAge = (date: Date | null) => {
        if (date) {
            const currentDate = new Date();
            const yearsDiff = currentDate.getFullYear() - date.getFullYear();

            // Check if date1's anniversary has occurred (if it's earlier in the year)
            if (
                currentDate.getMonth() < date.getMonth() ||
                (currentDate.getMonth() === date.getMonth() &&
                    currentDate.getDate() < date.getDate())
            ) {
                return yearsDiff - 1; // Subtract 1 if the anniversary hasn't occurred yet
            }
            return yearsDiff; //
        }
        return 0;
    };

    return (
        <>
            <Grid container sx={{justifyContent: 'center'}}>
                {!isMobile && (
                    <Grid item xs={12} md={1}>
                        <Box sx={{display: {xs: 'none', md: 'block'}, textAlign: 'center', marginRight: '10px'}}>
                            <GoogleAd
                                client="ca-pub-7424330364505566"
                                slot="7617979683"
                                style={{
                                    display: 'block',
                                    textAlign: 'center',
                                    minHeight: '300px', // Set a min height for the ad
                                    width: '100%',
                                }}
                                adFormat="vertical"
                                responsive="true"
                            />
                        </Box>
                    </Grid>
                )}


                <Grid item xs={12} md={isMobile ? 12 : 10}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            background: '#F0F0F0',
                            color: '#333',
                            flex: 1,
                            marginTop: '20px',
                            boxShadow: '0px -5px 15px 1px rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: props.isMobile ? 'flex-start' : 'center',
                                paddingLeft: props.isMobile ? '30px' : 0,
                            }}
                        >
                            <FormControl
                                sx={{
                                    marginTop: '20px',
                                    alignItems: props.isMobile ? 'flex-start' : 'center',
                                }}
                            >
                                <FormLabel sx={{color: '#333', fontWeight: 'bold'}}>
                                    Auto tüüp
                                </FormLabel>
                                <RadioGroup
                                    row={!props.isMobile}
                                    defaultValue="SÕIDUAUTO"
                                    name="auto-type-group"
                                    onChange={e => setAuto(e.target.value as AutoTüüp)}
                                >
                                    <FormControlLabel
                                        value={AutoTüüp.SÕIDUAUTO}
                                        control={<Radio sx={{color: '#0061F2'}}/>}
                                        label="Sõiduauto"
                                    />
                                    <FormControlLabel
                                        value={AutoTüüp.KAUBIK}
                                        control={<Radio sx={{color: '#0061F2'}}/>}
                                        label="Kaubik"
                                    />
                                    <FormControlLabel
                                        value={AutoTüüp.MOOTORRATAS}
                                        control={<Radio sx={{color: '#0061F2'}}/>}
                                        label="Mootorratas"
                                    />
                                    <FormControlLabel
                                        value={AutoTüüp.RATASTRAKTOR}
                                        control={<Radio sx={{color: '#0061F2'}}/>}
                                        label="Ratastraktor"
                                    />
                                    <FormControlLabel
                                        value={AutoTüüp.MAASTIKUSÕIDUK}
                                        control={<Radio sx={{color: '#0061F2'}}/>}
                                        label="Maastikusõiduk"
                                    />
                                </RadioGroup>
                            </FormControl>
                            <FormControl
                                sx={{
                                    marginTop: '10px',
                                    alignItems: props.isMobile ? 'flex-start' : 'center',
                                }}
                            >
                                <FormLabel sx={{color: '#333', fontWeight: 'bold'}}>
                                    Mootori tüüp
                                </FormLabel>
                                <RadioGroup
                                    row={!props.isMobile}
                                    defaultValue="SISEPÕLEMISMOOTORIGA"
                                    name="engine-type-group"
                                    onChange={e => setMootor(e.target.value as MootoriTüüp)}
                                >
                                    <FormControlLabel
                                        value={MootoriTüüp.SISEPÕLEMISMOOTORIGA}
                                        control={<Radio sx={{color: '#0061F2'}}/>}
                                        label="Sisepõlemismootoriga"
                                    />
                                    <FormControlLabel
                                        value={MootoriTüüp.ELEKTRI}
                                        control={<Radio sx={{color: '#0061F2'}}/>}
                                        label="Elektri"
                                    />
                                    <FormControlLabel
                                        value={MootoriTüüp.HÜBRIID}
                                        control={<Radio sx={{color: '#0061F2'}}/>}
                                        label="Hübriid"
                                    />
                                </RadioGroup>
                            </FormControl>
                            <FormControl
                                sx={{
                                    marginTop: '10px',
                                    alignItems: props.isMobile ? 'flex-start' : 'center',
                                }}
                            >
                                <FormLabel sx={{color: '#333', fontWeight: 'bold'}}>
                                    CO2 hindamismeetod
                                </FormLabel>
                                <RadioGroup
                                    row={!props.isMobile}
                                    defaultValue="WLTP"
                                    name="co2 type"
                                    onChange={e => setCo2Tüüp(e.target.value as CO2Tüüp)}
                                >
                                    <FormControlLabel
                                        value={CO2Tüüp.WLTP}
                                        control={<Radio sx={{color: '#0061F2'}}/>}
                                        label="WLTP"
                                    />
                                    <FormControlLabel
                                        value={CO2Tüüp.NEDC}
                                        control={<Radio sx={{color: '#0061F2'}}/>}
                                        label="NEDC"
                                    />
                                    <FormControlLabel
                                        value={CO2Tüüp.PUUDUB}
                                        control={<Radio sx={{color: '#0061F2'}}/>}
                                        label="Puudub"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: props.isMobile ? 'column' : 'row',
                                flexWrap: 'wrap',
                                justifyContent: 'space-between',
                                marginTop: '10px',
                            }}
                        >
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={et}>
                                <DatePicker
                                    label="Esmane registreerimine"
                                    value={selectedDate}
                                    onChange={(date: Date | null) => {
                                        setSelectedDate(date);
                                        const age = calculateAge(date);
                                        setVanus(age); // Assuming you have a setVanus function
                                    }}
                                    renderInput={dateProps => (
                                        <TextField
                                            sx={{margin: '8px', width: props.isMobile ? '100%' : '48%'}}
                                            {...dateProps}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                            <TextField
                                label={'CO2 väljaheide (g/km)'}
                                type="number"
                                onChange={e => setCo2(e.target.value as unknown as number)}
                                sx={{margin: '8px', width: props.isMobile ? '100%' : '48%'}}
                            />
                            <TextField
                                label={'Täismass (kg)'}
                                type="number"
                                onChange={e => setMass(e.target.value as unknown as number)}
                                sx={{margin: '8px', width: props.isMobile ? '100%' : '48%'}}
                            />
                            <TextField
                                label={'Töömaht (cm³)'}
                                type="number"
                                onChange={e => setMaht(e.target.value as unknown as number)}
                                sx={{margin: '8px', width: props.isMobile ? '100%' : '48%'}}
                            />
                            <TextField
                                label={'Mootori võimsus (kw)'}
                                type="number"
                                onChange={e => setVõimsus(e.target.value as unknown as number)}
                                sx={{margin: '8px', width: props.isMobile ? '100%' : '48%'}}
                            />
                        </Box>
                        <TotalSection
                            aastamaks={aastamaks}
                            registreerimismaks={registreerimismaks}
                        />
                    </Box>
                    <Box sx={{textAlign: 'center', marginTop: '20px'}}>
                        <GoogleAd
                            client="ca-pub-7424330364505566"
                            slot="4061107540"
                            style={{
                                display: 'block',
                                textAlign: 'center'
                            }}
                            adFormat="fluid"
                            layout="in-article"
                            responsive="true"
                        />
                    </Box>
                </Grid>


                {!isMobile && (
                    <Grid item xs={12} md={1}>
                        <Box sx={{display: {xs: 'none', md: 'block'}, textAlign: 'center', marginLeft: '10px'}}>
                            <GoogleAd
                                client="ca-pub-7424330364505566"
                                slot="7617979683"
                                style={{
                                    display: 'block',
                                    textAlign: 'center',
                                    minHeight: '300px',
                                    width: '100%',
                                }}
                                adFormat="vertical"
                                responsive="true"
                            />
                        </Box>
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default Content;
